import TC from "../template_utilities/templateColumns";
import NC from "../template_utilities/PeriodicalNestedColumns";
import panel from "./settingspanel.vue";
//add pivot variable somewhere for flagging pivot tables, will be used in reports.ts
export const agent_performance_periodical_combined_optimized: any = {
    cubeParameters: {
        measures: [
            "PerformanceCube.gciForPeriod",
            "PerformanceCube.transactionForPeriod",
            "PerformanceCube.volumeForPeriod",
            "PerformanceCube.gciForPeriodRxd",
            "PerformanceCube.gciForPeriodEur",
            "PerformanceCube.volumeForPeriodRxd",
            "PerformanceCube.teamIDMeasure",
            "PerformanceCube.volumeForPeriodEur",
        ],
        order: [
            ["personCube.uniquepersonid", "asc"],
            ["personCube.lastname", "asc"],
            ["personCube.firstname", "asc"],
        ],
        dimensions: [
            "PerformanceCube.regionid",
            "PerformanceCube.personid",
            "RegionCube.region",
            "personCube.idperson",
            "personCube.officeUniqueid",
            "personCube.primaryofficename",
            "personCube.lastname",
            "personCube.firstname",
            "personCube.officeUniqueid",
            "personCube.officeconstituentid",
            "personCube.mainofficename",
            "personCube.TeamName",
            "personCube.teamllcid",
            "personCube.TeamID",
            "personCube.uniquepersonid",
            "personCube.tenuredays",
            "personCube.primarytitle",
            "personCube.isactive",
            "personCube.reportingcurrencyiso",
            "personCube.idllcperson",
            "personCube.display_title",
            "personCube.titleprimary",
            "personCube.title",
            "personCube.subregionname",
        ],
        mainCubeParameter: "PerformanceCube",
        filterParameter: "PerformanceCube.officeid",
        timeParameter: "PerformanceCube.transactiondate",
    },

    columns: [
        TC.region,
        TC.subRegion,
        TC.mainOfficeName,
        TC.personOfficeUniqueID,
        TC.personOfficeConstituentID,
        TC.personCurrentTeamName,
        TC.teamllcid,
        TC.lastName,
        TC.firstName,
        TC.uniqueID,
        TC.personConstituentID,
        TC.tenureDays,
        TC.primaryTitle,
        TC.status,
    ],
    nestedColumns: NC.agentPerformanceCombined,
    pivot: {
        x: [
            "personCube.region",
            "personCube.subregionname",
            "personCube.primaryofficename",
            "personCube.officeUniqueid",
            "OfficeCube.officeConstituentId",
            "TeamCube.teamname",
            "TeamCube.teamllcid",
            "personCube.lastname",
            "personCube.firstname",
            "personCube.uniquepersonid",
            "personCube.idllcperson",
            "personCube.tenuredays",
            "personCube.primarytitle",
            "personCube.isactive",
            "PerformanceCube.officeid",
            "personCube.reportingcurrencyiso",
        ],
        y: ["PerformanceCube.transactiondate"],
    },
    settingsPanel: panel,
};
