import { BaseService } from '../base.service';
import { ResponseWrapper, ErrorWrapper } from '../util';

export class RolesService extends BaseService {
  static get entity() {
    return 'roles';
  }

  static async getRolesForRegionDropDown() {
    try {
      let response = await this.request().get(`${this.entity}/region?sort=remaxTitle`);
      return new ResponseWrapper(response, response.data.data);
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static checkRoleIsRegionalRole(roleId) {
    try {
          /*
          sysAdmin:               1,
          territoryAdmin:         2,
          regionalAdmin:          3,
          brokerOwner:            4,
          agent:                  5,
          regionPrincipalOfFranchisee:  6,
          officer:                7,
          regionalStaff:          8,
          officeStaff:            9, 
          managingDirector:       10,
          businessDevelopment:    12,
          officePrincipalOfFranchisee : 20,
          agentInTraining:        21
          */

          const regionalRoleIds = ["3", "6", "8", "20"];

          return regionalRoleIds.includes(roleId);
      
    } catch (error) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }

  }
}
